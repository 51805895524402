import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';

const DIT_RESULT = {
  APPROVE: 'Approve',
  REVIEW: 'Review',
  DENY: 'Deny',
  YES: 'Y',
  NO: 'N',
};

function SecureMFAModal({ showSecureMFAModal, toggle, fetcher, identityData, requestAuthorizationSMFA }) {
  const getPhoneTrustVerificationResult = () => {
    let phoneTrustResult;
    let phoneVerificationResult;
    for (let i = 0; i < fetcher.data?.details?.length; i++) {
      // check the result for 'phoneTrust' and 'phoneVerification'
      if (fetcher.data?.details[i]?.key === 'phoneTrust') {
        phoneTrustResult = fetcher.data?.details[i].value;
      }
      if (fetcher.data?.details[i]?.key === 'phoneVerification') {
        phoneVerificationResult = fetcher.data?.details[i]?.value;
      }

      if (phoneTrustResult && phoneVerificationResult) {
        return { phoneTrustResult, phoneVerificationResult };
      }
    }

    return { phoneTrustResult, phoneVerificationResult };
  };

  const { phoneTrustResult, phoneVerificationResult } = getPhoneTrustVerificationResult();
  const showVerify =
    fetcher.data?.decision === DIT_RESULT.APPROVE &&
    phoneTrustResult === DIT_RESULT.YES &&
    phoneVerificationResult === DIT_RESULT.YES;

  return (
    <Modal isOpen={showSecureMFAModal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Identity Verification</ModalHeader>
      <ModalBody>
        {fetcher.state !== 'idle' && (
          <>
            Please wait while we prepare to verify your information...
            <div className="text-center m-4">
              <Spinner style={{ width: '3rem', height: '3rem' }} />
            </div>
          </>
        )}
        {fetcher.state === 'idle' && (
          <>
            {showVerify ? (
              <div className="text-center">
                <p>
                  We will attempt to verify your identity by texting you a link to your mobile phone number ending in{' '}
                  <strong>{identityData?.mobileNumber?.slice(-4)}</strong>.
                </p>
                <p>Message and data rates may apply.</p>
                <Button
                  type="button"
                  className="btn btn-primary"
                  onClick={requestAuthorizationSMFA}
                  disabled={fetcher.state !== 'idle'}
                >
                  Yes, send me a text
                </Button>
              </div>
            ) : (
              <>
                {fetcher.data?.decision === DIT_RESULT.REVIEW && (
                  <>
                    <h3 className="text-center failure-header">We&apos;re sorry!</h3>
                    <div className="text-center">
                      <p>
                        <strong>
                          We could not approve your information.
                          <br /> Please try again with different information.{' '}
                        </strong>
                      </p>
                    </div>
                  </>
                )}
                {fetcher.data?.decision === DIT_RESULT.DENY && (
                  <>
                    <h3 className="text-center failure-header">We&apos;re sorry!</h3>
                    <div className="text-center">
                      <p>
                        <strong>
                          Your information is under review.
                          <br /> Please try again later.{' '}
                        </strong>
                      </p>
                    </div>
                  </>
                )}
                {fetcher.data?.decision === DIT_RESULT.APPROVE && !showVerify && (
                  <>
                    <h3 className="text-center failure-header">We&apos;re sorry!</h3>
                    <div className="text-center">
                      <p>
                        <strong>
                          We could not approve your information.
                          <br /> Please try again with different information.{' '}
                        </strong>
                      </p>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </ModalBody>
    </Modal>
  );
}

SecureMFAModal.propTypes = {
  fetcher: PropTypes.object,
  identityData: PropTypes.object,
  requestAuthorizationSMFA: PropTypes.func,
  toggle: PropTypes.func,
  showSecureMFAModal: PropTypes.bool,
};

export default SecureMFAModal;
