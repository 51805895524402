import { useEffect, useState, useCallback } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import CreditScoreTermsOfUse from './CreditScoreTermsOfUse';
import CreditScoreSignUpModal from '../../CreditScoreSignUpModal.jsx';
import { useFetcher } from '@remix-run/react';
import SecureMFAModal from './SecureMFAModal';
import MobileVerifyModal from './MobileVerifyModal';
import { SMFA_RESULT } from '../../../utils/constants.js';
import '../../../style/components/creditWidget.css';

const CREDIT_SCORE_MODAL = 'CREDIT_SCORE_MODAL';
const TERMS_MODAL = 'TERMS_MODAL';
const SECURE_MFA_MODAL = 'SECURE_MFA_MODAL';
const ERROR_MODAL = 'ERROR_MODAL';
const MOBILE_VERIFY_MODAL = 'MOBILE_VERIFY_MODAL';
const DENY_MODAL = 'DENY_MODAL';
const RETRY_MODAL = 'RETRY_MODAL';

export default function CreditScoreSignUp({ onComplete }) {
  const [showCreditScoreModal, setShowCreditScoreModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [identityData, setIdentityData] = useState(null);
  const [showSecureMFAModal, setShowSecureMFAModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showMobileVerifyModal, setShowMobileVerifyModal] = useState(false);
  const [showDenyModal, setShowDenyModal] = useState(false);
  const [showRetryModal, setShowRetryModal] = useState(false);
  const [smfaResent, setSmfaResent] = useState(false);

  const fetcher = useFetcher();
  const toggle = () => setShowCreditScoreModal(!showCreditScoreModal);
  const toggleConfirmationModal = () => setShowSecureMFAModal(!showSecureMFAModal);
  const toggleTermsModal = () => setShowTermsModal(!showTermsModal);
  const toggleErrorModal = () => setShowErrorModal(!showErrorModal);
  const toggleMobileVerifyModal = () => setShowMobileVerifyModal(!showMobileVerifyModal);
  const toggleDenyModal = () => setShowDenyModal(!showDenyModal);
  const toggleRetryModal = () => setShowRetryModal(!showRetryModal);

  // Hide all modals.
  const hideModals = () => {
    setShowCreditScoreModal(false);
    setShowTermsModal(false);
    setShowSecureMFAModal(false);
    setShowErrorModal(false);
    setShowMobileVerifyModal(false);
    setShowDenyModal(false);
    setShowRetryModal(false);
  };

  // First hides all modals, then shows the one we selected.
  const setActiveModal = useCallback((activeModal) => {
    const MODAL_SHOW = {
      [CREDIT_SCORE_MODAL]: setShowCreditScoreModal,
      [TERMS_MODAL]: setShowTermsModal,
      [SECURE_MFA_MODAL]: setShowSecureMFAModal,
      [ERROR_MODAL]: setShowErrorModal,
      [MOBILE_VERIFY_MODAL]: setShowMobileVerifyModal,
      [DENY_MODAL]: setShowDenyModal,
      [RETRY_MODAL]: setShowRetryModal,
    };

    hideModals();
    MODAL_SHOW[activeModal](true);
  }, []);

  // Step 1: Get the DIT token and submit the form data.
  const submitIdentityData = () => {
    fetcher.submit(identityData, { method: 'POST', action: '/assessDigitalIdentity' });

    setActiveModal(SECURE_MFA_MODAL);
  };

  // Step 2, get the SMFA token and attempt to send the text with a link to /validate
  const requestAuthorizationSMFA = () => {
    // This does the token request and the initiate request in one step,
    // so we don't have to fool with two round trips and trying to
    // fire off the second request based on the results of the first
    // while avoiding an infinite loop.
    fetcher.submit(
      { mobile: identityData?.mobileNumber },
      { method: 'POST', action: '/secure-mfa/request-and-initiate' }
    );

    // Step 3 happens entirely in the MobileVerifyModal
    setActiveModal(MOBILE_VERIFY_MODAL);
  };

  const enrollCreditScore = () => {
    const [year, month, day] = identityData.dateOfBirth ? identityData.dateOfBirth.split('-') : [null, null, null];

    const enrollmentData = {
      firstName: identityData.firstName,
      lastName: identityData.lastName,
      street1: identityData.addressLine1,
      street2: identityData.addressLine2,
      city: identityData.city,
      state: identityData.state,
      postalCode: identityData.zipCode,
      dateOfBirth: `${year}-${month}-${day}`,
      socialSecurityNumber: `${identityData.ssn1}${identityData.ssn2}${identityData.ssn3}`,
      mobilePhone: identityData.mobileNumber.replace(/\D/g, ''),
    };

    fetcher.submit(enrollmentData, { method: 'POST', action: '/enroll-credit-score' });
  };

  // Separate endpoint for /initiate so we can resend the link via SMS.
  const resendPhoneVerification = () => {
    const mfaToken = fetcher.data?.smfaToken;

    fetcher.submit(
      { mfaToken, mobile: identityData?.mobileNumber },
      { method: 'POST', action: '/secure-mfa/initiate' }
    );
    setSmfaResent(true);
  };

  // Show the error modal if we have errors, or close the modal if we have a success.
  useEffect(() => {
    if (fetcher.data?.efxClientLibSettings) {
      onComplete(fetcher.data?.efxClientLibSettings);
      hideModals();
      return;
    }

    if (
      fetcher.data?.efxErrorCode ||
      fetcher.data?.initiateMfaResult?.efxErrorCode ||
      fetcher.data?.error ||
      fetcher.data?.code === '500'
    ) {
      setActiveModal(fetcher.data?.retry ? RETRY_MODAL : ERROR_MODAL);
    }
  }, [
    fetcher?.data,
    fetcher.data?.code,
    fetcher.data?.efxErrorCode,
    fetcher.data?.error,
    fetcher.data?.initiateMfaResult?.efxErrorCode,
    fetcher.data?.retry,
    setActiveModal,
    onComplete,
  ]);

  return (
    <div className="cta-container cta-container-transparent">
      <div className="cta-content">
        <Button className="cta-button btn-primary" color="primary" onClick={toggle}>
          Get Your Credit Score
        </Button>
        <p>
          <small className="text-muted disclaimer">
            Checking your credit score
            <br /> will not impact it in any way.
          </small>
        </p>
      </div>

      <CreditScoreSignUpModal
        isOpen={showCreditScoreModal}
        toggle={toggle}
        setShowModal={setShowCreditScoreModal}
        setShowTermsModal={setShowTermsModal}
        setIdentityData={setIdentityData}
      />

      {/* Step 1, submits to /assessDigitalIdentity */}
      <CreditScoreTermsOfUse
        isOpen={showTermsModal}
        toggle={toggleTermsModal}
        back={() => {
          setShowTermsModal(false);
          setShowCreditScoreModal(true);
        }}
        submitIdentityData={submitIdentityData}
      />

      {/* Step 2, submits to /request-and-initiate */}
      <SecureMFAModal
        showSecureMFAModal={showSecureMFAModal}
        toggle={toggleConfirmationModal}
        fetcher={fetcher}
        identityData={identityData}
        requestAuthorizationSMFA={requestAuthorizationSMFA}
      />

      {/* Step 3, waits for the user to click the link that was sent to them. */}
      <MobileVerifyModal
        showMobileVerifyModal={showMobileVerifyModal}
        toggle={toggleMobileVerifyModal}
        smfaResent={smfaResent}
        resetSMFAResent={() => setSmfaResent(false)}
        resendPhoneVerification={resendPhoneVerification}
        startPolling={fetcher.data?.initiateMfaResult?.otpLifecycle?.status === SMFA_RESULT.SUCCESS}
        smfaToken={fetcher.data?.smfaToken}
        smfaSessionId={fetcher.data?.initiateMfaResult?.sessionId}
        mobile={identityData?.mobile}
        showErrorModal={() => setActiveModal(ERROR_MODAL)}
        showDenyModal={() => setActiveModal(DENY_MODAL)}
        enrollCreditScore={enrollCreditScore}
      />

      {/* TODO: Refactor these into a single modal with a message prop. */}

      {/* Error Modal */}
      <Modal isOpen={showErrorModal} toggle={toggleErrorModal}>
        <ModalHeader toggle={toggleErrorModal}>Verification Error</ModalHeader>
        <ModalBody>
          {fetcher.data?.error || 'We were unable to verify your information.'}
          {fetcher?.data?.initiateMfaResult?.additionalErrorDetails?.errorCode && (
            <p>Error Code: {fetcher?.data?.initiateMfaResult?.additionalErrorDetails?.errorCode}</p>
          )}
        </ModalBody>
      </Modal>

      {/* Deny message for Step 3 */}
      <Modal isOpen={showDenyModal} toggle={toggleDenyModal}>
        <ModalHeader toggle={toggleDenyModal}>We&apos;re sorry!</ModalHeader>
        <ModalBody>
          <div className="text-center">
            <p>
              <strong>
                We are unable to confirm your identity
                <br /> and retrieve your credit score. <br />
                Please try again with different information.
              </strong>
            </p>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={showRetryModal} toggle={toggleRetryModal}>
        <ModalHeader toggle={toggleRetryModal}>We&apos;re sorry!</ModalHeader>
        <ModalBody>
          <div className="text-center">
            <p>
              <strong>
                We are unable able to complete your enrollment at this time. <br />
                Try again later.
              </strong>
            </p>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
