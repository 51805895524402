import { useRef, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFetcher, useRouteLoaderData } from '@remix-run/react';
import { Button, Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import { useInterval } from 'usehooks-ts';
import { SMFA_RESULT } from '../../../utils/constants';

function MobileVerifyModal({
  showMobileVerifyModal,
  toggle,
  smfaResent,
  resetSMFAResent,
  resendPhoneVerification,
  startPolling,
  smfaToken,
  smfaSessionId,
  mobile,
  showErrorModal,
  showDenyModal,
  enrollCreditScore,
}) {
  const fetcher = useFetcher();
  const statusFetcher = useFetcher();
  const intervalRef = useRef(0);
  const statusIntervalRef = useRef(0);
  const { profile } = useRouteLoaderData('root');
  const [isVerified, setIsVerified] = useState(false);
  const [enrolling, setEnrolling] = useState(false);
  const [resendTimer, setResendTimer] = useState(false);

  const handleResendPhoneVerification = useCallback(() => {
    resendPhoneVerification();
    setResendTimer(15);
  }, [resendPhoneVerification, setResendTimer]);

  useInterval(() => {
    if (resendTimer <= 0) {
      return;
    }
    setResendTimer(resendTimer - 1);
    resetSMFAResent();
  }, 1000);

  // This effect initiates polling of /secure-mfa/check.
  useEffect(() => {
    if (smfaToken && startPolling && !intervalRef.current) {
      const interval = setInterval(() => {
        fetcher.submit(
          { profileId: profile.id, accessToken: smfaToken },
          { method: 'POST', action: '/secure-mfa/check' }
        );
      }, 5000);

      intervalRef.current = interval;
    }

    return () => {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    };
  }, [fetcher, profile.id, smfaToken, startPolling]);

  // Here we cancel /secure-mfa/check polling when it returns isVerified, or !success
  useEffect(() => {
    if (fetcher.data && !fetcher.data.success && intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      showErrorModal();
    }

    if (fetcher.data?.isVerified && intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      setIsVerified(true);
    }
  }, [fetcher, showErrorModal]);

  // Once isVerified is true, initiate polling the /secure-mfa/status endpoint.
  useEffect(() => {
    if (!enrolling && isVerified && !statusIntervalRef.current) {
      const interval = setInterval(() => {
        statusFetcher.submit(
          {
            consumerIdentifier: {
              subjectIdentifier: mobile,
              subjectType: 'MDN',
            },
            accessToken: smfaToken,
            sessionId: smfaSessionId,
          },
          {
            method: 'POST',
            action: '/secure-mfa/status',
            encType: 'application/json',
          }
        );
      }, 5000);

      statusIntervalRef.current = interval;
    }

    return () => {
      clearInterval(statusIntervalRef.current);
      statusIntervalRef.current = null;
    };
  }, [isVerified, mobile, smfaSessionId, smfaToken, statusFetcher, enrolling]);

  // Here we cancel /secure-mfa/status polling when it returns an error or response.path
  useEffect(() => {
    if (enrolling) {
      return;
    }

    if (statusFetcher.data?.error && statusIntervalRef.current) {
      clearInterval(statusIntervalRef.current);
      statusIntervalRef.current = null;
      showErrorModal();
    }

    if (statusFetcher.data?.response?.path && statusIntervalRef.current) {
      clearInterval(statusIntervalRef.current);
      statusIntervalRef.current = null;
      setEnrolling(true);

      const result = statusFetcher.data?.response.path;

      if (result === SMFA_RESULT.GREEN || result === SMFA_RESULT.YELLOW) {
        enrollCreditScore();
      } else if (result === SMFA_RESULT.ORANGE || result === SMFA_RESULT.RED) {
        showDenyModal();
      } else {
        showErrorModal();
      }
    }
  }, [
    enrollCreditScore,
    showDenyModal,
    showErrorModal,
    statusFetcher.data,
    statusFetcher.data?.error,
    enrolling,
    setEnrolling,
  ]);

  return (
    <Modal isOpen={showMobileVerifyModal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Identity Verification</ModalHeader>
      <ModalBody className="text-center d-flex flex-column align-items-center">
        <p>Please verify your identity through the link we just sent</p>

        {enrolling && <Spinner className="mb-2" size="lg" />}
        {resendTimer > 0 && `Please wait another ${resendTimer} seconds before trying again.`}
        {smfaResent && <span className="col-xs-12 alert alert-success">A new link has been sent</span>}
        <Button type="button" onClick={handleResendPhoneVerification} disabled={resendTimer > 0}>
          Resend Link
        </Button>
      </ModalBody>
    </Modal>
  );
}

MobileVerifyModal.propTypes = {
  resendPhoneVerification: PropTypes.func,
  toggle: PropTypes.func,
  showMobileVerifyModal: PropTypes.bool,
  smfaResent: PropTypes.bool,
  startPolling: PropTypes.bool,
  smfaToken: PropTypes.string,
  smfaSessionId: PropTypes.string,
  mobile: PropTypes.string,
  showErrorModal: PropTypes.func,
  showDenyModal: PropTypes.func,
  enrollCreditScore: PropTypes.func,
};

export default MobileVerifyModal;
