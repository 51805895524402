import { Col, Row, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import CreditScoreSignUp from './DashBoard/creditScore/CreditScoreSignUp';
import moment from 'moment';

function CreditScoreWidget({ efxClientLibSettings, profile, onComplete }) {
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [creditScore, setCreditScore] = useState(null);
  const [lastUpdatedDate, setLastUpdatedDate] = useState(null);
  const [efxClientLibConfig, setEfxClientLibConfig] = useState(efxClientLibSettings);

  useEffect(() => {
    if (!isLoading || !efxClientLibConfig) {
      setLoading(false);
      return;
    }

    const run = async () => {
      try {
        const clientLibraryInstance = await window?.EfxConsumerApiClient(efxClientLibConfig);
        const latestCreditScore = await clientLibraryInstance?.getCreditScoreLatest();

        setCreditScore(latestCreditScore?.providerViews?.[0]);

        if (profile?.profile?.equifax_enrollment?.refresh_date) {
          setLastUpdatedDate(moment(profile?.profile?.equifax_enrollment?.refresh_date).format('MMMM dd, yyyy'));
        } else if (latestCreditScore?.generatedDate) {
          setLastUpdatedDate(moment(latestCreditScore?.generatedDate).format('MMMM Do YYYY'));
        } else {
          setLastUpdatedDate(moment().format('MMMM dd, yyyy'));
        }
      } catch (e) {
        console.error(e);
        setError(e);
      } finally {
        setLoading(false);
      }
    };

    run();
  }, [
    isLoading,
    efxClientLibConfig,
    setCreditScore,
    setLoading,
    setError,
    profile?.profile?.equifax_enrollment?.refresh_date,
  ]);

  const handleComplete = useCallback(
    (settings) => {
      setEfxClientLibConfig(settings);
      setLoading(true);
      onComplete && onComplete();
    },
    [setEfxClientLibConfig, setLoading, onComplete]
  );

  if (isLoading) {
    return (
      <Row>
        <Col xs={12} className="d-flex align-items-center flex-column">
          <Spinner />
        </Col>
      </Row>
    );
  }

  if (error) {
    return (
      <Row>
        <Col xs={12} className="d-flex align-items-center flex-column">
          {error?.message || 'An error occurred while fetching credit score'}
        </Col>
      </Row>
    );
  }

  return (
    <>
      {!creditScore ? (
        <CreditScoreSignUp onComplete={handleComplete} />
      ) : (
        <Row>
          <Col xs={12} className="d-flex align-items-center flex-column">
            <div className="credit-score-circle">
              <span className="score">{creditScore?.score}</span>
            </div>
            <p>As of {lastUpdatedDate}</p>
          </Col>
          <Col xs={12}>
            <ul>
              {creditScore?.scoreReasons?.slice(2)?.map((reason, index) => {
                return <li key={index}>{reason?.description}</li>;
              })}
            </ul>
          </Col>
        </Row>
      )}
    </>
  );
}

CreditScoreWidget.propTypes = {
  efxClientLibSettings: PropTypes.object,
  profile: PropTypes.shape({
    id: PropTypes.number,
  }),
};

export default CreditScoreWidget;
